var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"course-list"},[_c('el-card',[_c('el-form',{ref:"form",attrs:{"inline":true,"size":"small","model":_vm.filterParams}},[_c('el-form-item',{attrs:{"label":"课程名称","prop":"courseName"}},[_c('el-input',{model:{value:(_vm.filterParams.courseName),callback:function ($$v) {_vm.$set(_vm.filterParams, "courseName", $$v)},expression:"filterParams.courseName"}})],1),_c('el-form-item',{attrs:{"label":"状态","prop":"status"}},[_c('el-select',{model:{value:(_vm.filterParams.status),callback:function ($$v) {_vm.$set(_vm.filterParams, "status", $$v)},expression:"filterParams.status"}},[_c('el-option',{attrs:{"label":"全部","value":""}}),_c('el-option',{attrs:{"label":"上架","value":"1"}}),_c('el-option',{attrs:{"label":"下架","value":"0"}})],1)],1),_c('el-form-item',[_c('el-button',{attrs:{"disabled":_vm.loading},on:{"click":_vm.handleReset}},[_vm._v("重置")]),_c('el-button',{attrs:{"type":"primary","disabled":_vm.loading},on:{"click":_vm.loadCourses}},[_vm._v("查询")])],1)],1)],1),_c('el-card',[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.$router.push({
            name: 'course-create'
          })}}},[_vm._v("添加课程")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%","margin-bottom":"20px"},attrs:{"data":_vm.courses,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"align":"center","type":"index","index":_vm.indexMethod,"width":"80","label":"编号"}}),_c('el-table-column',{attrs:{"prop":"id","label":"ID","align":"center"}}),_c('el-table-column',{attrs:{"prop":"courseName","align":"center","label":"课程名称"}}),_c('el-table-column',{attrs:{"prop":"price","align":"center","label":"价格"}}),_c('el-table-column',{attrs:{"prop":"sortNum","align":"center","label":"排序"}}),_c('el-table-column',{attrs:{"prop":"status","align":"center","label":"上架状态","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status === 0)?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("下架")]):_c('el-tag',[_vm._v("上架")])]}}])}),_c('el-table-column',{attrs:{"prop":"price","label":"操作","width":"180","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status === 0)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.StatusChange(1, scope.row.id)}}},[_vm._v("上架")]):_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.StatusChange(0, scope.row.id)}}},[_vm._v("下架")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.$router.push({
                name: 'course-edit',
                params: {
                  courseId: scope.row.id
                }
              })}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.$router.push({
                name: 'course-section',
                params: {
                  courseId: scope.row.id
                }
              })}}},[_vm._v("内容管理")])]}}])})],1),_c('div',{staticStyle:{"text-align":"right"}},[_c('el-pagination',{attrs:{"current-page":_vm.page.current,"page-sizes":[10, 20, 50, 100],"page-size":10,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){return _vm.$set(_vm.page, "current", $event)},"update:current-page":function($event){return _vm.$set(_vm.page, "current", $event)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }