













































































































import Vue from "vue";
import { getQueryCourses, changeState } from "@/api/course";
import { Form } from "element-ui";

export default Vue.extend({
  name: "CourseList",
  data() {
    return {
      filterParams: {
        courseName: "",
        status: ""
      },
      courses: [],
      page: {
        currentPage: 1,
        pageSize: 10
      },
      total: 0,
      loading: true
    };
  },

  created() {
    this.loadCourses();
  },

  methods: {
    handleSizeChange(val: number) {
      this.page.pageSize = val;
      this.page.currentPage = 1;
      this.loadCourses();
    },
    handleCurrentChange(val: number) {
      this.page.currentPage = val;
      this.loadCourses();
    },
    indexMethod(index: number) {
      return (this.page.currentPage - 1) * 10 + index + 1;
    },
    async loadCourses() {
      this.loading = true;
      const { data } = await getQueryCourses(Object.assign(this.filterParams, this.page));
      data.data.records.forEach((item: any) => {
        item.isStatusLoading = false;
      });
      this.courses = data.data.records;
      this.total = data.data.total;
      this.loading = false;
    },

    handleReset() {
      (this.$refs.form as Form).resetFields();
      this.page.currentPage = 1;
      this.loadCourses();
    },

    async StatusChange(status: number, id: number) {
      await changeState({
        courseId: id,
        status: status
      });
      this.$message.success(`操作成功`);
      this.loadCourses();
    }
  }
});
