






import Vue from "vue";
import CourseList from "./components/CourseList.vue";

export default Vue.extend({
  name: "CourseIndex",
  components: {
    CourseList
  }
});
